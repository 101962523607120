import React from "react";
import MarchandsList from "./Marchands/MarchandsList";
import UsersList from "./User/UsersList";

const Dashboard = () => {
  return (
    <div className="">
      <div className="container-fluid">
        <UsersList/>
      </div>
    </div>
  );
};

export default Dashboard;
