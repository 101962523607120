import React from 'react'
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';


const Publicite = () => {
  return (
    <div>
      <li className="nav-item menu-open">
        <Link to="/" className="nav-link">
          <i className="bi bi-megaphone mr-2" />
          <p className='text-dark font-weight-bold'>
            Publicité
            <i className="right fas fa-angle-left"></i>
          </p>
        </Link>
        <ul className="nav nav-treeview ms-3">
          <li className="nav-item">
            <Link to="/ajouterPubliciteMarchand" className="nav-link">
            <i className="bi bi-plus-circle mr-2"/>
              <p>Marchand</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/ajouterPubliciteMarche" className="nav-link">
            <i className='bi bi-megaphone mr-2'/>
              <p>Marché</p>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/liste-Publicite" className="nav-link">
            <i className='bi bi-list-ol mr-2'/>
              <p>Liste</p>
            </Link>
          </li>
        </ul>
      </li>
    </div>
  )
}

export default Publicite