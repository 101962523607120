import React, { useEffect , useState, useRef} from "react";
import $ from "jquery";
import "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import { BuildTable, merchantsStore } from "../DataComponent";



const MarchandsList = () => {
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

    useEffect(()=> {
      const fetchData = async () => {
        try {
          const response = await fetch("https://prodapi.odon-afrik.com/api/los/adminos/merchants");

          if (!response.ok) {
            throw new Error("Erreur lors du chargement des données des marchands");
          }
          const data = await response.json();
          merchantsStore.setMerchants(data.data);
          setLoading(false);

        } catch (error) {
          console.error("Error:", error);
          setError(error.message);
          setLoading(false);
        }
      }
      fetchData();
    }, []);

    if (loading) {
      return <div className="alert alert-info">Chargement des données...</div>;
    }

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

  return (
    <div className="table-responsive">
      <table ref={tableRef} className="table table-striped w-100">
        <thead>
          <tr>
            <th>Etablissements</th>
            <th>Téléphone</th>
            <th>Pays</th>
            <th>Adresse</th>
            <th>Type</th>
            <th>Média</th>
          </tr>
        </thead>
        <tbody>
          {merchantsStore.merchants.map((merchant, index) => (
            <tr key={index}>
              <td>{merchant.etablissement}</td>
              <td>{merchant.telephone}</td>
              <td>{merchant.pays}</td>
              <td>{merchant.adresse}</td>
              <td>{merchant.type}</td>
              <td className="d-flex">
                {merchant.medias.map((media, mediaIndex) => (
                  <div 
                    style={{maxWidth : "60px",
                      flex : "row",
                      gap: "2px",
                      flexWrap: "wrap"
                     }} 
                    key={mediaIndex} className="p-2">
                    <img
                      src={media.url}
                      alt="photo du marché"
                      className="img-fluid"
                      style={{ maxHeight: "100px", maxWidth: "150px" }}
                    />
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarchandsList;
