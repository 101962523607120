import React, { useState, useEffect, useRef } from 'react';
import "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";


import { marketsStore } from '../DataComponent';

const MarketsList = () => {
  const [markets, setMarkets] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://prodapi.odon-afrik.com/api/los/adminos/markets");
        if (!response.ok) {
          throw new Error("Erreur lors du chargement des données de markets");
        }
        const data = await response.json();
        setMarkets(data.data);
        marketsStore.setMarkets(data.data);  // Mise à jour du store
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (markets.length > 0 && tableRef.current) {
      const table = $(tableRef.current).DataTable();
      return () => table.destroy();  // Nettoyage au démontage
    }
  }, [markets]);

  return (
    <div className="table-responsive">
      <table ref={tableRef} className="table table-striped w-100">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Pays</th>
            <th>Adresse</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
          {marketsStore.markets.map((market, index) => (
            <tr key={index}>
              <td>{market.nom}</td>
              <td>{market.pays}</td>
              <td>{market.adresse}</td>
              <td>
                <img
                  src={market.image}
                  alt="photo du marché"
                  className="img-fluid"
                  style={{ maxHeight: "100px", maxWidth: "150px" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarketsList;
