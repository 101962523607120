import React from 'react'

const Header = () => {
  return (
    <div>
      <header>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="fake_url"
                role="button"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a href="fake_url" className="nav-link">
                Home
              </a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a href="fake_url" className="nav-link">
                Contact
              </a>
            </li>
          </ul>
          {/* Right navbar links */}
          
        </nav>
      </header>
    </div>
  );
}

export default Header