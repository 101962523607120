import React from 'react'
import { Link } from 'react-router-dom';


const Marche = () => {
  return (
    <div>
      <li className="nav-item menu-open">
        <Link href="/" className="nav-link">
          <i className="bi bi-cash mr-2"/>
          <p className='text-dark font-weight-bold'>
            Marché
            <i className="right fas fa-angle-left"></i>
          </p>
        </Link>
        <ul className="nav nav-treeview ms-3">
          <li className="nav-item">
            <Link to="/ajouterMarche" className="nav-link">
            <i className="bi bi-plus-circle mr-2"/>
              <p>Ajouter</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/liste-Marches" className="nav-link">
            <i className='bi bi-list-ol mr-2'/>
              <p>Liste</p>
            </Link>
          </li>
        </ul>
      </li>
    </div>
  )
}

export default Marche