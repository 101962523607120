import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Notifier = () => {
  return (
    <div className="table-responsive">
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link to="/envoyerNotification" className="nav-link">
            <i className=" bi bi-megaphone mr-2" />
            <p className='text-dark font-weight-bold'>Notification</p>
          </Link>
        </li>

        <li className="nav-item menu-open">
          <Link to="/envoyerBonASavoir" className="nav-link">
            <i className="bi bi-megaphone mr-2" />
            <p className='text-dark font-weight-bold'>
              Bon à Savoir
            </p>
          </Link>
          <ul className="nav nav-treeview ms-3">
            <li className="nav-item">
              <Link to="/liste-BonASavoir" className="nav-link">
                <i className="bi bi-list-ol mr-2" />
                Liste des Bons à Savoir
              </Link>
            </li>
            {/* Tu peux ajouter d'autres éléments de sous-menu ici si nécessaire */}
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Notifier;
