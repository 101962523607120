import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {URL} from "../Dashboard/DataComponent";

const AuthenticationPage = ({ onLogin }) => {
    const [telephone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const authURL = URL + 'hyper/login'

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(authURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ telephone, password })
            });

            console.log(response.body)
            if (response.ok) {
                const data = await response.json();
                setError('');
                onLogin(data.data.token);  // Appel de la prop `onLogin` avec le token
                navigate('/dashboard');  // Navigation vers le dashboard
            } else {
                setError('Invalid credentials');
            }
        } catch (err) {
            setError('An error occurred');
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4 shadow-sm" style={{ width: '20rem' }}>
                <h3 className="card-title text-center mb-4">Login</h3>
                {error && <div className="alert alert-danger">{error}</div>}
                <form onSubmit={handleLogin}>
                    <div className="mb-3">
                        <label htmlFor="telephone" className="form-label">Telephone</label>
                        <input
                            type="text"
                            className="form-control"
                            id="telephone"
                            value={telephone}
                            onChange={(e) => setTelephone(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Login</button>
                </form>
            </div>
        </div>
    );
};

export default AuthenticationPage;
