import React, { useState, useEffect } from 'react';
import { URL } from '../DataComponent';

const BonASavoirList = () => {
    const [bonsASavoir, setBonsASavoir] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchBonsASavoir = async () => {
            try {
                const response = await fetch(URL + 'goodtoknow');
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const resObject = await response.json();
                setBonsASavoir(resObject.data);
                setLoading(false);
            } catch (error) {
                setError('Erreur : ' + error.message);
                setLoading(false);
            }
        };

        fetchBonsASavoir();
    }, []);

    if (loading) {
        return <div className="alert alert-info">Chargement des données...</div>;
    }

    if (error) {
        return <div className="alert alert-danger">{error}</div>;
    }

    return (
        <div className="container pt-2">
            <h2 className="mb-4">Liste des Bons à Savoir</h2>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Nom</th>
                        <th scope="col">Description</th>
                        <th scope="col">Image</th>
                    </tr>
                </thead>
                <tbody>
                    {bonsASavoir.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>
                                {item.image && (
                                    <img 
                                        src={item.image} 
                                        alt={item.name} 
                                        className="img-thumbnail" 
                                        style={{ width: '250px', height: 'auto' }}
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default BonASavoirList;
