import React, { useState, useEffect } from "react";
import { URL } from "../DataComponent";
import { usersStore } from "../DataComponent";

import 'bootstrap-icons/font/bootstrap-icons.css';

const Pubmarchand = () => {
  const [id, setId] = useState("");
  const [image, setImage] = useState(null);
  const [endAt, setEndAt] = useState("");
  const [users, setUsers] = useState([]);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setUsers(usersStore.users);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", id);
    formData.append("model", "Merchant");
    formData.append("image", image);
    formData.append("end_at", endAt);

    try {
      const response = await fetch(URL + "ads", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
      setSuccess("La publicité a été ajoutée avec succès !");
      setError("");
    } catch (error) {
      console.error("Error:", error);
      setError("Une erreur s'est produite lors de l'ajout de la publicité.");
      setSuccess("");
    }
  };

  return (
    <div className="container pt-5">
      <div className="card shadow">
        <div className="card-header">
          <h3 className="card-title"><i className="bi bi-plus-circle mr-2"/>
          Ajouter une publicité</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="userSelect" className="form-label">
                Sélectionner un Utilisateur
              </label>
              <select
                id="userSelect"
                className="form-select"
                value={id}
                onChange={(e) => setId(e.target.value)}
                required
              >
                <option value="">Sélectionnez un utilisateur</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.nom} {user.prenom}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">
                Image (PNG ou JPEG, max 2MB):
              </label>
              <input
                type="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={(e) => setImage(e.target.files[0])}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Date de fin (Format YY-MM-DD):
              </label>
              <input
                type="date"
                className="form-control"
                value={endAt}
                onChange={(e) => setEndAt(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
          {success && <div className="alert alert-success mt-3">{success}</div>}
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default Pubmarchand;
