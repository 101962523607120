import React, { useState, useEffect } from "react";
import { URL } from "../DataComponent";
import { marketsStore } from "../DataComponent";

import 'bootstrap-icons/font/bootstrap-icons.css';


const Pubmarche = () => {
  const [id, setId] = useState("");
  const [image, setImage] = useState(null);
  const [endAt, setEndAt] = useState("");
  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    setMarkets(marketsStore.markets);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", id);
    formData.append("model", "Market");
    formData.append("image", image);
    formData.append("end_at", endAt);

    try {
      const response = await fetch(URL + "ads", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="container pt-5">
      <div className="card shadow">
        <div className="card-header">
          <h3 className="card-title"><i className="bi bi-plus-circle mr-2"/>
          Ajouter une publicité Marché</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="userSelect" className="form-label">
                Sélectionner un Marché
              </label>
              <select
                id="userSelect"
                className="form-select"
                value={id}
                onChange={(e) => setId(e.target.value)}
                required
              >
                <option value="">Sélectionnez un Marché</option>
                {marketsStore.markets.map((market) => (
                  <option key={market.id} value={market.id}>
                    {market.nom} {market.adresse}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">
                Image (PNG ou JPEG, max 2MB):
              </label>
              <input
                type="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={(e) => setImage(e.target.files[0])}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Date de fin (Format YY-MM-DD):
              </label>
              <input
                type="date"
                className="form-control"
                value={endAt}
                onChange={(e) => setEndAt(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Pubmarche;