import React from "react";
import User from "./User";
import Marchand from "./Marchand";
import Marche from "./Marche";
import Publicite from "./Publicite";
import { Link } from "react-router-dom";
import Notification from "../Dashboard/Notification/Notification";
import Notifier from "./Notifier";

const Main = () => {
  return (
    <div>
      <aside className="main-sidebar elevation-2">
        {/* Brand Logo */}
        {/* <Link to="/" className="brand-link">
          <span className="brand-text d-flex ml-3 font-weight-light">Dashboard</span>
        </Link> */}
        {/* Sidebar */}
        <div className="sidebar ">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src="dist/img/odon.png"
                className="img-circle elevation-2"
                alt="User Image"
              />
              
            </div>
            <div className="info">
              <a href="#" className="d-block text-dark font-weight-bold">
                Admin
              </a>
            </div>
          </div>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <User />
              <Marchand />
              <Marche />
              <Publicite />
              <Notifier/>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Main;
