import React, { useEffect, useState } from "react";
import { URL } from "../DataComponent";
import "bootstrap-icons/font/bootstrap-icons.css";
import { marketsStore } from "../DataComponent";

const URlNotif = URL + "notifications/send";

const Notification = () => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleNoti, setTitleNoti] = useState("");
  const [bodyNoti, setBodyNoti] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    setMarkets(marketsStore.markets);
  }, []);

  const handleSend = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", id);
    formData.append("model", "Market");
    formData.append("title", title);
    formData.append("description", description);
    formData.append("title_noti", titleNoti);
    formData.append("body_noti", bodyNoti);

    try {
      const response = await fetch(URlNotif, {
        method: "POST",
        body: formData,
      });

      // console.log(Object.fromEntries(formData.entries()));

      if (!response.ok) {
        throw new Error("Response was not ok");
      }

      const notif = await response.json();
      console.log("Success:", notif);
      setSuccess("Notification sent successfully!");
      setError("");
    } catch (error) {
      setError("Erreur: " + error.message);
      setSuccess("");
    }
  };

  return (
    <div className="container pt-2">
      <h2 className="mb-4">Create Notification</h2>
      <form onSubmit={handleSend} className="needs-validation" noValidate>
        <div className="mb-3">
          <label htmlFor="userSelect" className="form-label">
            Sélectionner un Marché
          </label>
          <select
            id="userSelect"
            className="form-select"
            value={id}
            onChange={(e) => setId(e.target.value)}
            required
          >
            <option value="">Sélectionnez un Marché</option>
            {markets.map((market) => (
              <option key={market.id} value={market.id}>
                {market.nom} - {market.adresse}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">
            Veuillez sélectionner un marché.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <div className="invalid-feedback">Please enter a title.</div>
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            id="description"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <div className="invalid-feedback">Please enter a description.</div>
        </div>
        <div className="mb-3">
          <label htmlFor="titleNoti" className="form-label">
            Notification Title
          </label>
          <input
            type="text"
            className="form-control"
            id="titleNoti"
            value={titleNoti}
            onChange={(e) => setTitleNoti(e.target.value)}
            required
          />
          <div className="invalid-feedback">
            Please enter a notification title.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="bodyNoti" className="form-label">
            Notification Body
          </label>
          <textarea
            className="form-control"
            id="bodyNoti"
            rows="3"
            value={bodyNoti}
            onChange={(e) => setBodyNoti(e.target.value)}
            required
          />
          <div className="invalid-feedback">
            Please enter the notification body.
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          <i className="bi bi-send"></i> Send Notification
        </button>
      </form>
      {error && <div className="alert alert-danger mt-4">{error}</div>}
      {success && <div className="alert alert-success mt-4">{success}</div>}
    </div>
  );
};

export default Notification;
