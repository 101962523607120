import React, { useEffect, useRef, useState } from 'react'
import "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import { pubsStore, BuildTable } from '../DataComponent';
import { useReducer } from 'react';


const PubList = () => {
  const tableRef = useRef(null);

    useEffect(()=> {
      const fetchData = async () => {
        try {
          const response = await fetch("https://prodapi.odon-afrik.com/api/los/adminos/ads");

          if (!response.ok) {
            throw new Error("Erreur lors du chargement des données de publicités");
          }
          const data = await response.json();
          pubsStore.setPubs(data.data);

        } catch (error) {
          console.error("Error:", error);
        }
      }
      fetchData();
    }, []);

    return (
      <div className="table-responsive">
        <table ref={tableRef} className="table table-striped w-100">
          <thead>
            <tr>
              <th>ID</th>
              <th>Model</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {pubsStore.pubs.map((pub, index) => (
            
              <tr key={index} className="">
                <td>{pub.id}</td>
                <td>{pub.via.split("\\")[2]}</td>
                <td>
                    <img
                      key={index}
                      src={pub.image}
                      alt="photo du produit"
                      className="img-fluid m-1"
                      style={{ maxHeight: "100px", maxWidth: "150px" }}
                    />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
}

export default PubList