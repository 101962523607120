import React, { useState, useEffect, useRef } from "react";
import $, { error } from "jquery";
import "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";

export const URL = "https://prodapi.odon-afrik.com/api/los/adminos/";

export const usersStore = {
  users: [],
  setExpUser(newUsers) {
    this.users = newUsers;
  },
};

export const merchantsStore = {
  merchants: [],
  setMerchants(newMerchants) {
    this.merchants = newMerchants;
  },
};


export const marketsStore = {
  markets: [],
  setMarkets(newMarkets) {
    this.markets = newMarkets;
  },
};

export const pubsStore = {
  pubs: [],
  setPubs(newPubs) {
    this.pubs = newPubs
  }
}

export const BuildTable = (elementTab) => {
  const tableRef = useRef(null);
  useEffect(() => {
    if (elementTab.length > 0) {
      const table = $(tableRef.current).DataTable();
      return () => {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
          table.destroy();
        }
      };
    }
  }, [elementTab]);

  return tableRef
}
