import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import { usersStore } from "../DataComponent";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://prodapi.odon-afrik.com/api/los/adminos/users");
        if (!response.ok) {
          throw new Error("Erreur lors du chargement des données");
        }
        const data = await response.json();
        setUsers(data.data);
        usersStore.setExpUser(data.data);  // Mise à jour du store
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (users.length > 0 && tableRef.current) {
      const table = $(tableRef.current).DataTable();
      return () => table.destroy();  // Nettoyage
    }
  }, [users]);

  return (
    <div className="table-responsive">
      <table ref={tableRef} className="table table-striped w-100">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prenom</th>
            <th>Telephone</th>
            <th>Email</th>
            <th>Activated</th>
            <th>Rôles</th>
          </tr>
        </thead>
        <tbody>
          {usersStore.users.map((user, index) => (
            <tr key={index}>
              <td>{user.nom}</td>
              <td>{user.prenom}</td>
              <td>{user.telephone}</td>
              <td>{user.email}</td>
              <td>{user.activated}</td>
              <td>
                {user.roles.map((role, roleIndex) => (
                  <p key={roleIndex}>{role}</p>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersList;
