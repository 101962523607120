import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';


const User = () => {
  return (
    <div>
      <ul className="nav nav-pills nav-sidebar flex-column">
        <li className="nav-item menu-open">
          <Link to="/" className="nav-link">
            <i className="bi bi-person-circle me-2 mr-2"/>
            <p className='text-dark font-weight-bold'>
              User
              <i className="right fas fa-angle-left ms-2"></i>
            </p>
          </Link>
          <ul className="nav nav-treeview ms-3">
            <li className="nav-item">
              <Link to="/ajouterUser" className="nav-link">
                <i className="bi bi-person-plus me-2 mr-2"></i>
                <p>Ajouter</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/liste-users" className="nav-link">
              <i className='bi bi-list-ol mr-2'/>
                <p>Liste</p>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default User;
