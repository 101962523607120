import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './Components/Header/Header';
import Menu from './Components/Menu/Menu';
import Footer from './Components/Footer/Footer';
import Dashboard from './Components/Dashboard/Dashboard';
import MarchandsList from './Components/Dashboard/Marchands/MarchandsList';
import UsersList from './Components/Dashboard/User/UsersList';
import MarketsList from './Components/Dashboard/Marches/MarketsList';
import PubList from './Components/Dashboard/Publicites/PubList';
import AddMarket from './Components/Dashboard/Marches/AddMarket';
import Pubmarchand from './Components/Dashboard/Publicites/Pubmarchand';
import Pubmarche from './Components/Dashboard/Publicites/Pubmarche';
import Notification from './Components/Dashboard/Notification/Notification';
import AuthenticationPage from "./Components/Authentification/AuthenticationPage";
import BonASavoir from './Components/Dashboard/Notification/BonASavoir';
import BonASavoirList from './Components/Dashboard/Notification/BonASavoirList';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    const handleLogin = (token) => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    return (
        <Router>
            <div className="wrapper">
                {!isAuthenticated ? (
                    <Routes>
                        <Route path="/login" element={<AuthenticationPage onLogin={handleLogin} />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                )  : (
                    <>
                        <Header />
                        <Menu />
                        <div className="content-wrapper">
                            <Routes>
                                <Route path="/" element={<Navigate to="/dashboard" />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/ajouterUser" element={<Dashboard />} />
                                <Route path="/liste-users" element={<UsersList />} />
                                <Route path="/ajouterMarchand" element={<Dashboard />} />
                                <Route path="/liste-Marchands" element={<MarchandsList />} />
                                <Route path="/ajouterMarche" element={<AddMarket />} />
                                <Route path="/liste-Marches" element={<MarketsList />} />
                                <Route path="/ajouterPubliciteMarchand" element={<Pubmarchand />} />
                                <Route path="/ajouterPubliciteMarche" element={<Pubmarche />} />
                                <Route path="/liste-Publicite" element={<PubList />} />
                                <Route path="/envoyerNotification" element={<Notification />} />
                                <Route path="/logout" element={<button onClick={handleLogout}>Logout</button>} />
                                <Route path="*" element={<Navigate to="/dashboard" />} />
                                <Route path="/envoyerBonASavoir" element={<BonASavoir />} />
                                <Route path="/liste-BonASavoir" element={<BonASavoirList />} />
                            </Routes>
                        </div>
                        <Footer />
                    </>
                )}
            </div>
        </Router>
    );
}

export default App;
