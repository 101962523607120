import React from 'react';
import { Link } from 'react-router-dom';


const Marchand = () => {
  return (
    <div>
      <li className="nav-item menu-open">
        <Link to="/" className="nav-link">
        <i className="bi bi-shop mr-2"/>
          <p className='text-dark font-weight-bold'>
            Marchand
            <i className="right fas fa-angle-left"></i>
          </p>
        </Link>
        <ul className="nav nav-treeview ms-3">
          <li className="nav-item">
            <Link to="/ajouterMarchand" className="nav-link">
            <i className="bi bi-plus-circle mr-2"/>
              <p>Ajouter</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/liste-Marchands" className="nav-link">
                <i className='bi bi-list-ol mr-2'/>
              <p>Liste</p>
            </Link>
          </li>
        </ul>
      </li>
    </div>
  );
};

export default Marchand;
