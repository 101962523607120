import React, { useState } from 'react';
import { URL } from '../DataComponent';

const notifBonASavoir = URL + "goodtoknow";

const BonASavoir = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const validate = () => {
        let isValid = true;
        let errorMessage = '';

        if (!name.trim()) {
            errorMessage = 'Le nom est requis';
            isValid = false;
        } else if (!description.trim()) {
            errorMessage = 'La description est requise';
            isValid = false;
        } else if (image) {
            const { type, size } = image;
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validTypes.includes(type)) {
                errorMessage = 'Le fichier doit être une image (jpeg, png, jpg)';
                isValid = false;
            } else if (size > 2048 * 1024) {
                errorMessage = 'L\'image ne doit pas dépasser 2 Mo';
                isValid = false;
            }
        }

        setError(errorMessage);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'image') {
            setImage(files[0]);
        } else if (name === 'name') {
            setName(value);
        } else if (name === 'description') {
            setDescription(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            if (image) {
                formData.append('image', image);
            }

            try {
                const response = await fetch(notifBonASavoir, {
                    method: 'POST',
                    body: formData,
                });

                console.log(Object.fromEntries(formData.entries()));


                if (!response.ok) {
                    console.log(response.json());
                    
                    throw new Error('Erreur lors de l\'envoi de la notification');
                }


                setSuccess('Données du formulaire envoyées avec succès !');

                setError('');
                console.log("Données envoyées :", { name, description, image });
            } catch (err) {
                console.log(err)
                setError('Erreur : ' + err.message);
                setSuccess('');
            }
        }
    };

    return (
        <div className="container pt-2">
            <h2 className="mb-4">Créer une Notification Bon à Savoir</h2>
            <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Nom</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">Le nom est requis.</div>
                </div>

                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        rows="3"
                        value={description}
                        onChange={handleChange}
                        required
                    />
                    <div className="invalid-feedback">La description est requise.</div>
                </div>

                <div className="mb-3">
                    <label htmlFor="image" className="form-label">Image</label>
                    <input
                        type="file"
                        className="form-control"
                        id="image"
                        name="image"
                        onChange={handleChange}
                        accept=".jpeg, .png, .jpg"
                    />
                    <div className="invalid-feedback">Veuillez sélectionner une image valide.</div>
                </div>

                <button type="submit" className="btn btn-primary">
                    <i className="bi bi-send"></i> Soumettre
                </button>
            </form>
            {error && <div className="alert alert-danger mt-4">{error}</div>}
            {success && <div className="alert alert-success mt-4">{success}</div>}
        </div>
    );
};

export default BonASavoir;
